/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/mfi.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/Mrfy_Church_NFT6.gltf"
    );

    const { actions } = useAnimations(animations, group);

    const playBounceAnimation = () => {
        console.log("actions: ", actions.Jump);
        audio.play();
        actions.Jump.reset().play();
        actions.Jump.loop = THREE.LoopOnce;
    }


    return (
        <group ref={group} onClick={()=>playBounceAnimation()} {...props} dispose={null}>
            <group name="Scene">
                <group name="Armature">
                    <skinnedMesh
                        scale={[2, 2, 2]}
                        name="Plane"
                        geometry={nodes.Plane.geometry}
                        material={materials.Material}
                        skeleton={nodes.Plane.skeleton}
                    />
                    <primitive object={nodes.Bone} />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/Mrfy_Church_NFT6.gltf");